<template>
  <div class="w-100 text-center">
    <i><h2 class="m-0">Reset Password</h2></i>
    <small>Please input your new password below to proceed</small>
    <div class="row justify-content-center pt-4">
      <div class="col-md-8">
        <b-form @submit.prevent="onSubmit">
          <MyInputFormText
            id="password"
            type="password"
            v-model="$v.password.$model"
            placeholder="Enter your new password"
            :classComponent="{
              'is-invalid': $v.password.$error,
              'is-valid': !$v.password.$invalid,
            }"
            :invalidMessage="[
              !$v.password.required ? 'This Field is Required' : '',
            ]"
          />
          <MyInputFormText
            id="login-repassword"
            type="password"
            v-model="$v.repassword.$model"
            placeholder="Re-enter your new password"
            :classComponent="{
              'is-invalid': $v.repassword.$error,
              'is-valid': !$v.repassword.$invalid,
            }"
            :invalidMessage="[
              !$v.repassword.required ? 'This Field is Required' : '',
            ]"
          />
          <div class="alert alert-danger text-center" v-if="errorMessage">
            <p class="my-2">{{ errorMessage }}</p>
          </div>
          <div class="row justify-content-center">
            <div class="col-8">
              <MyButtonSubmit
                submitText="Reset Password"
                variant="secondary"
                :disabledComponent="isLoading"
              />
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>
<script>
import { toaster } from "@/_helpers";
import MyInputFormText from "@/components/form/MyInputFormText";
import MyButtonSubmit from "@/components/form/MyButtonSubmit";

import { required, sameAs } from "vuelidate/lib/validators";

export default {
  name: "ResetPassword",
  components: {
    MyInputFormText,
    MyButtonSubmit
  },
  data() {
    return {
      errorMessage: null,
      password: null,
      repassword: null,
    }
  },
  validations: {
    password: {required},
    repassword: {required, sameAsPassword: sameAs("password")}
  },
  methods: {
    async onSubmit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const data = {
          "password": this.password,
          "confirm_password": this.password,
          "key": this.$route.params.key
        }
        try {
          const res = await this.$api.user.resetPassword(data)
          if (res.status === 200) {
            toaster.make(res.data.message, "success")
            this.$router.push("/login")
          }
          else this.errorMessage = res.data.message
        } catch (e) {
          console.error(e);
        }
      }
    }
  }
};
</script>
