<template>
  <b-modal
    id="modal-forgot-password"
    title="Forgot your password?"
    centered
    hide-header
    hide-footer
  >
    <div class="text-center">
      <h2 class="roboto">Forgot your password?</h2>
      <p class="text-grey">
        Enter your email address below and we'll send you a link to reset your password.
      </p>
      <form @submit.prevent="sendRequest">
        <input type="email" v-model="email" class="form-control mb-2" placeholder="Enter your email address" required>
        <div v-if="message" class="alert" :class="[alertClass]" v-html="message"></div>
        <button type="submit" class="btn btn-secondary btn-block">
          Send Request
          <font-awesome icon="arrow-right" class="forgot-password-request" />
        </button>
        <button type="submit" class="btn btn-block" @click="$bvModal.hide('modal-forgot-password')">Cancel</button>
      </form>
    </div>
  </b-modal>
</template>
<script>
export default {
  name: "ModalForgotPassword",
  data() {
    return {
      alertClass: null,
      message: null,
      email: null,
    }
  },
  methods: {
    async sendRequest() {
      try {
        const res = await this.$api.user.forgotPassword(this.email)
        if (res.status === 200) this.alertClass = "alert-success"
        else this.alertClass = "alert-danger"
        this.message = res.data.message
      } catch (e) {
        console.error(e);
      }
    }
  }
}
</script>
<style scoped>
.forgot-password-request {
  position: absolute;
  right: 15px;
  margin-top: .25rem;
  margin-right: .5rem;
}
</style>
