<template>
  <section class="landing-page">
    <div class="container-fluid">
      <div class="row h-100 align-items-center">
        <div class="col-md-7 d-none d-lg-flex align-items-center justify-content-center text-white">
          <div class="text-center">
            <img src="@/assets/img/logomark_white_background.svg" height="100" class="mb-4"/>
            <h1 class="roboto">WHIZLIZ</h1>
            <h2 class="roboto subtitle">Indonesia's Biggest Online Jewellery Platform</h2>
          </div>
        </div>
        <div id="login-form" class="col-md-5 d-flex justify-content-center align-items-center">
          <ResetPassword v-if="$route.path.indexOf('/reset-password') == 0" />
          <div v-else class="w-100 text-center">
            <i><h2 class="m-0">Login</h2></i>
            <small>Please input your information below to proceed</small>
            <div class="row justify-content-center pt-4">
              <div class="col-md-8">
                <b-form @submit.prevent="onSubmit">
                  <MyInputFormText
                    id="username"
                    v-model="$v.userName.$model"
                    placeholder="Enter your email address"
                    :classComponent="{
                      'is-invalid': $v.userName.$error,
                      'is-valid': !$v.userName.$invalid,
                    }"
                    :invalidMessage="[
                      !$v.userName.required ? 'This Field is Required' : '',
                    ]"
                  />
                  <MyInputFormText
                    id="login-password"
                    type="password"
                    v-model="$v.password.$model"
                    placeholder="Enter your password"
                    :classComponent="{
                      'is-invalid': $v.password.$error,
                      'is-valid': !$v.password.$invalid,
                    }"
                    :invalidMessage="[
                      !$v.password.required ? 'This Field is Required' : '',
                    ]"
                  />
                  <div class="alert alert-danger text-center" v-if="errorMessage">
                    <p class="my-2">{{ errorMessage }}</p>
                  </div>
                  <div class="alert alert-success text-center" v-else-if="activationMessage">
                    <p class="my-2">{{ activationMessage }}</p>
                  </div>
                  <div class="row px-4">
                    <div class="col d-flex align-items-center pr-0">
                      <p class="w-100 m-0 text-center text-small" v-b-modal.modal-forgot-password>
                        Forgot your password?
                      </p>
                    </div>
                    <div class="col">
                      <MyButtonSubmit submitText="Log In" variant="secondary" :disabledComponent="isLoading" />
                    </div>
                  </div>
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalForgotPassword />
  </section>
</template>
<script>
import { toaster } from "@/_helpers";
import MyInputFormText from "../components/form/MyInputFormText";
import MyButtonSubmit from "../components/form/MyButtonSubmit";
import ResetPassword from "../components/forgotPassword/ResetPassword";
import ModalForgotPassword from "../components/forgotPassword/ModalForgotPassword";

import { required } from "vuelidate/lib/validators";


export default {
  name: "Login",
  validations: {
    userName: {
      required,
    },
    password: {
      required,
    },
  },
  components: {
    MyInputFormText,
    MyButtonSubmit,
    ResetPassword,
    ModalForgotPassword,
  },
  data() {
    return {
      userName: null,
      password: null,
      //! UMUM
      isLoading: false,
      activationMessage: null,
      errorMessage: null,
    };
  },
  mounted() {
    setTimeout(() => {
      document.getElementById('login-form').scrollIntoView({behavior: 'smooth'})
    }, 3000)
    const currentPath = this.$route.path
    if (currentPath.indexOf('/activate') == 0) this.activateAccount(this.$route.params.id)
    this.$api.user.logout()
  },
  methods: {
    async onSubmit() {
      this.isLoading = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        toaster.make("Please fill the form correctly", "danger")
        this.isLoading = false;
        return
      }
      try {
        const res = await this.$api.user.login(this.userName, this.password)
        if (res.status === 200) this.$router.replace('/')
        else this.errorMessage = res.data.message
      } catch (e) {
        console.error(e)
      }
      this.isLoading = false;
    },
    async activateAccount(id) {
      try {
        const res = await this.$api.user.activateAccount(id)
        if (res.status === 200) this.activationMessage = "Thank you! Your activation is success, welcome :)"
        else this.errorMessage = res.data.message
      } catch (e) {
        console.error(e);
      }
    }
  },
  watch: {
    errorMessage() {
      this.activationMessage = null
    }
  }
};
</script>
