var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-100 text-center"},[_vm._m(0),_c('small',[_vm._v("Please input your new password below to proceed")]),_c('div',{staticClass:"row justify-content-center pt-4"},[_c('div',{staticClass:"col-md-8"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('MyInputFormText',{attrs:{"id":"password","type":"password","placeholder":"Enter your new password","classComponent":{
            'is-invalid': _vm.$v.password.$error,
            'is-valid': !_vm.$v.password.$invalid,
          },"invalidMessage":[
            !_vm.$v.password.required ? 'This Field is Required' : '',
          ]},model:{value:(_vm.$v.password.$model),callback:function ($$v) {_vm.$set(_vm.$v.password, "$model", $$v)},expression:"$v.password.$model"}}),_c('MyInputFormText',{attrs:{"id":"login-repassword","type":"password","placeholder":"Re-enter your new password","classComponent":{
            'is-invalid': _vm.$v.repassword.$error,
            'is-valid': !_vm.$v.repassword.$invalid,
          },"invalidMessage":[
            !_vm.$v.repassword.required ? 'This Field is Required' : '',
          ]},model:{value:(_vm.$v.repassword.$model),callback:function ($$v) {_vm.$set(_vm.$v.repassword, "$model", $$v)},expression:"$v.repassword.$model"}}),(_vm.errorMessage)?_c('div',{staticClass:"alert alert-danger text-center"},[_c('p',{staticClass:"my-2"},[_vm._v(_vm._s(_vm.errorMessage))])]):_vm._e(),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-8"},[_c('MyButtonSubmit',{attrs:{"submitText":"Reset Password","variant":"secondary","disabledComponent":_vm.isLoading}})],1)])],1)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('i',[_c('h2',{staticClass:"m-0"},[_vm._v("Reset Password")])])
}]

export { render, staticRenderFns }