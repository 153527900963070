var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"landing-page"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row h-100 align-items-center"},[_vm._m(0),_c('div',{staticClass:"col-md-5 d-flex justify-content-center align-items-center",attrs:{"id":"login-form"}},[(_vm.$route.path.indexOf('/reset-password') == 0)?_c('ResetPassword'):_c('div',{staticClass:"w-100 text-center"},[_c('i',[_c('h2',{staticClass:"m-0"},[_vm._v("Login")])]),_c('small',[_vm._v("Please input your information below to proceed")]),_c('div',{staticClass:"row justify-content-center pt-4"},[_c('div',{staticClass:"col-md-8"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('MyInputFormText',{attrs:{"id":"username","placeholder":"Enter your email address","classComponent":{
                    'is-invalid': _vm.$v.userName.$error,
                    'is-valid': !_vm.$v.userName.$invalid,
                  },"invalidMessage":[
                    !_vm.$v.userName.required ? 'This Field is Required' : '',
                  ]},model:{value:(_vm.$v.userName.$model),callback:function ($$v) {_vm.$set(_vm.$v.userName, "$model", $$v)},expression:"$v.userName.$model"}}),_c('MyInputFormText',{attrs:{"id":"login-password","type":"password","placeholder":"Enter your password","classComponent":{
                    'is-invalid': _vm.$v.password.$error,
                    'is-valid': !_vm.$v.password.$invalid,
                  },"invalidMessage":[
                    !_vm.$v.password.required ? 'This Field is Required' : '',
                  ]},model:{value:(_vm.$v.password.$model),callback:function ($$v) {_vm.$set(_vm.$v.password, "$model", $$v)},expression:"$v.password.$model"}}),(_vm.errorMessage)?_c('div',{staticClass:"alert alert-danger text-center"},[_c('p',{staticClass:"my-2"},[_vm._v(_vm._s(_vm.errorMessage))])]):(_vm.activationMessage)?_c('div',{staticClass:"alert alert-success text-center"},[_c('p',{staticClass:"my-2"},[_vm._v(_vm._s(_vm.activationMessage))])]):_vm._e(),_c('div',{staticClass:"row px-4"},[_c('div',{staticClass:"col d-flex align-items-center pr-0"},[_c('p',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-forgot-password",modifiers:{"modal-forgot-password":true}}],staticClass:"w-100 m-0 text-center text-small"},[_vm._v(" Forgot your password? ")])]),_c('div',{staticClass:"col"},[_c('MyButtonSubmit',{attrs:{"submitText":"Log In","variant":"secondary","disabledComponent":_vm.isLoading}})],1)])],1)],1)])])],1)])]),_c('ModalForgotPassword')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-7 d-none d-lg-flex align-items-center justify-content-center text-white"},[_c('div',{staticClass:"text-center"},[_c('img',{staticClass:"mb-4",attrs:{"src":require("@/assets/img/logomark_white_background.svg"),"height":"100"}}),_c('h1',{staticClass:"roboto"},[_vm._v("WHIZLIZ")]),_c('h2',{staticClass:"roboto subtitle"},[_vm._v("Indonesia's Biggest Online Jewellery Platform")])])])
}]

export { render, staticRenderFns }